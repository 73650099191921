<template>
    <div>
        <v-alert
        outlined
        type="error"
        elevation="2"
        transition="scale-transition"
        >
            The requested site cannot be found!
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'Error404'
}
</script>

